// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import { StatusDot } from './Dot'
import Tooltip from '../Tooltip'
import Taxes from '../../Typography/Taxes'
import {
  BackgroundContainer,
  ColumnsWrapper,
  TableWrapper,
  Container,
  Table,
  TableForThead,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  TdInput,
  PriceInput,
  ColumnRight,
  ColumnLeft,
  BuildingLabel,
  SaveContainer,
  Legend,
  Modified,
  Feedback,
  BuildingContainer,
} from './Table'
import { ButtonBlue } from '../Button'
import VatCheckbox from './VatCheckbox'
import HidePriceCheckbox from './HidePriceCheckbox'

type Lot = {|
  id: string,
  label: string,
  status: string,
  priceExcludingVAT: number,
  vatAvailable: number[],
  hidePrice: boolean,
  parkings: ?({ id: string, label: string }[]),
|}

type Floor = {|
  id: string,
  label: string,
  order: number,
  lots: Lot[],
|}

type Building = {|
  id: string,
  label: string,
  floors: Floor[],
|}

const isValidPrice = (price: ?number): boolean => {
  return price !== undefined && price !== null && price > 0
}

type Props = {|
  buildings: { id: string, label: string }[],
  vatList: number[],
  onSave: () => Promise<void>,
  currentBuilding: Building,
  onSelectBuilding: (buildingId: string) => void,
  lotsUpdates: Lot[],
  onLotPriceEdition: (lot: Lot, value: number) => void,
  onLotVatEdition: (lot: Lot, value: number) => void,
  onLotPriceHideEdition: (lot: Lot, value: boolean) => void,
  onImportLots: (file: string) => Promise<void>,
  onExportLots: () => Promise<void>,
  closeFeedback: () => void,
  importSucceed: ?boolean,
  importError: ?string,
|}

const EstatesEdition = ({
  buildings,
  vatList,
  onSave,
  currentBuilding,
  onSelectBuilding,
  lotsUpdates,
  onLotPriceEdition,
  onLotVatEdition,
  onLotPriceHideEdition,
  onImportLots,
  onExportLots,
  closeFeedback,
  importSucceed,
  importError,
}: Props) => {
  const { t } = useTranslation()

  return (
    <BackgroundContainer>
      {typeof importSucceed === 'boolean' && (
        <Feedback
          close={closeFeedback}
          succeed={importSucceed}
          message={importError}
        />
      )}
      <ColumnsWrapper>
        <ColumnLeft>
          <BuildingContainer>
            {buildings.map(oneBuilding => (
              <BuildingLabel
                key={oneBuilding.id}
                onClick={() => onSelectBuilding(oneBuilding.id)}
                isActive={oneBuilding.id === currentBuilding.id}
              >
                {oneBuilding.label}
              </BuildingLabel>
            ))}
          </BuildingContainer>
        </ColumnLeft>

        <TableWrapper>
          <TableForThead>
            <Thead>
              <Tr>
                <Th width="88px">{t('programEdition.floor')}</Th>
                <Th width="72px">
                  <span style={{ marginLeft: '8px' }}>
                    {t('programEdition.estate')}
                  </span>
                </Th>
                <Th width="64px">{t('programEdition.status')}</Th>
                <Th width="108px">{t('programEdition.exclusiveTax')}</Th>
                {vatList.map((oneVat, index) => (
                  <Th key={index} width="64px">
                    <Taxes amount={oneVat} />
                  </Th>
                ))}
                <Th width="64px">{t('programEdition.price')}</Th>
                <Th width="72px">
                  <span style={{ marginRight: '8px' }}>
                    {t('programEdition.parking')}
                  </span>
                </Th>
              </Tr>
            </Thead>
          </TableForThead>
          <Container>
            <Table>
              <Thead>
                <Tr>
                  <Th width="88px" />
                  <Th width="72px" />
                  <Th width="64px" />
                  <Th width="108px" />
                  {vatList.map((oneVat, index) => (
                    <Th key={index} width="64px" />
                  ))}
                  <Th width="64px" />
                  <Th width="72px" />
                </Tr>
              </Thead>

              {currentBuilding.floors.map(oneFloor => {
                return (
                  <Tbody key={oneFloor.id}>
                    {oneFloor.lots.map((oneLot, lotIndex) => {
                      const lotIsSold = oneLot.status === 'sold'
                      const lotIsUnvailable = oneLot.status === 'unavailable'

                      const lotUpdate = lotsUpdates.find(
                        oneLotChanged => oneLotChanged.id === oneLot.id,
                      )

                      const isFirstLot = lotIndex === 0
                      const isUpdated = !!lotUpdate

                      return (
                        <Tr key={oneLot.id} isUnvailable={lotIsUnvailable}>
                          {isFirstLot && (
                            <Td rowSpan={oneFloor.lots.length}>
                              <span>{t(`floor.${oneFloor.label}.longue`)}</span>
                            </Td>
                          )}

                          <Td isUnvailable={lotIsUnvailable} isSold={lotIsSold}>
                            <Tooltip
                              content={
                                isUpdated
                                  ? t('programEdition.hasModified')
                                  : undefined
                              }
                            >
                              <Modified
                                isUpdated={isUpdated}
                                isFirst={isFirstLot}
                              />
                            </Tooltip>
                            <span style={{ marginLeft: '8px' }}>
                              {oneLot.label}
                            </span>
                          </Td>

                          <Td>
                            <StatusDot value={oneLot.status} />
                          </Td>

                          <TdInput>
                            <PriceInput
                              defaultValue={oneLot.priceExcludingVAT}
                              onValueChange={({ floatValue }) => {
                                onLotPriceEdition(oneLot, floatValue)
                              }}
                              isValidValue={
                                lotUpdate
                                  ? isValidPrice(lotUpdate.priceExcludingVAT)
                                  : true
                              }
                              disabled={lotIsSold}
                              isUnvailable={lotIsUnvailable}
                              isSold={lotIsSold}
                            />
                          </TdInput>

                          {vatList.map((oneVat, index) => (
                            <Td key={index}>
                              <VatCheckbox
                                onClick={() => onLotVatEdition(oneLot, oneVat)}
                                isActive={
                                  lotUpdate
                                    ? lotUpdate.vatAvailable.includes(oneVat)
                                    : oneLot.vatAvailable.includes(oneVat)
                                }
                                vatAvailable={
                                  lotUpdate
                                    ? lotUpdate.vatAvailable
                                    : oneLot.vatAvailable
                                }
                                lotIsUnvailable={lotIsUnvailable}
                                lotIsSold={lotIsSold}
                              />
                            </Td>
                          ))}

                          <Td>
                            <HidePriceCheckbox
                              onClick={value =>
                                onLotPriceHideEdition(oneLot, value)
                              }
                              value={
                                lotUpdate
                                  ? !lotUpdate.hidePrice
                                  : !oneLot.hidePrice
                              }
                              lotIsUnvailable={lotIsUnvailable}
                              lotIsSold={lotIsSold}
                            />
                          </Td>

                          <Td isUnvailable={lotIsUnvailable} isSold={lotIsSold}>
                            <span style={{ marginRight: '8px' }}>
                              <Tooltip
                                content={
                                  oneLot.parkings
                                    ? oneLot.parkings.length > 0 &&
                                      oneLot.parkings.map(
                                        (oneParking, index) => (
                                          <div
                                            key={index}
                                            style={
                                              oneLot.parkings &&
                                              index !==
                                                oneLot.parkings.length - 1
                                                ? {
                                                    marginBottom: '10px',
                                                    color: Colors.white50,
                                                  }
                                                : {
                                                    color: Colors.white50,
                                                  }
                                            }
                                          >
                                            {oneParking.label}
                                          </div>
                                        ),
                                      )
                                    : undefined
                                }
                              >
                                {(oneLot.parkings && oneLot.parkings.length) ||
                                  ''}
                              </Tooltip>
                            </span>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                )
              })}
            </Table>
          </Container>
        </TableWrapper>
        <ColumnRight>
          <Legend onImport={onImportLots} onExport={onExportLots} />
          <SaveContainer>
            <div>
              {lotsUpdates.length} {t('programEdition.modifications')}
            </div>
            <ButtonBlue
              onClick={onSave}
              disabled={
                lotsUpdates.length === 0 ||
                lotsUpdates.some(
                  lot =>
                    !isValidPrice(lot.priceExcludingVAT) ||
                    lot.vatAvailable.length === 0,
                )
              }
            >
              {t('programEdition.apply')}
            </ButtonBlue>
          </SaveContainer>
        </ColumnRight>
      </ColumnsWrapper>
    </BackgroundContainer>
  )
}

export default React.memo<Props>(EstatesEdition)
