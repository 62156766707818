// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import { Colors } from '../Colors'
import { RobotoMedium, RobotoBold } from '../Typography/Fonts'
import TypologySvg from '../icons/TypologySvg'
import AreaSvg from '../icons/AreaSvg'
import OrientationSvg from '../icons/OrientationSvg'
import FloorSvg from '../icons/FloorSvg'
import ClockSvg from '../icons/ClockSvg'
import DashedSeparator from '../DashedSeparator'

const Action = styled.div`
  cursor: pointer;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  color: ${({ isDisabled }) => (isDisabled ? Colors.white10 : Colors.white30)};
  line-height: 38px;
  transition: color 0.15s ease-in-out;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};

  path {
    transition: fill-opacity 0.15s ease-in-out, fill 0.15s ease-in-out;
    fill-opacity: ${({ isDisabled }) => (isDisabled ? 0.1 : 0.3)};
  }

  &:hover {
    color: ${({ color }) => color};
    path {
      fill-opacity: 1;
      fill: ${({ color }) => color};
    }
  }
`

export const LeftLoginAction = styled(Action)`
  bottom: 30px;
  left: 30px;

  &:hover {
    color: ${Colors.white100};
    path {
      fill-opacity: 1;
      fill: ${Colors.white100};
    }
  }
`

export const RightAction = styled(Action)`
  bottom: 30px;
  right: 30px;
`

export const LeftAction = styled(Action)`
  bottom: 30px;
  left: 30px;
`

const HistoryButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  width: 20px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  background-color: ${Colors.blue};
  z-index: 10;

  svg {
    transform: ${({ displayHistory }) =>
      displayHistory ? 'scaleX(-1)' : 'none'};
    transition: transform 0.25s ease-in-out;
  }

  path {
    transition: transform 0.25s ease-in-out;
    transform-origin: 50% 50%;
  }

  &:hover path {
    transform: rotate(-45deg);
  }
`

export const LotHistoryButton = styled(HistoryButton)`
  height: 40px;
  top: 30px;
`

export const ProgramsHistoryButton = styled(HistoryButton)`
  height: 32px;
  top: 16px;
`

export const TextNormal = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white50};
`

export const SubTitle = styled(TextNormal)`
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  height: 14px;

  span {
    margin-left: 3px;
    color: ${Colors.blue};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Separator = styled(DashedSeparator)`
  margin-top: 20px;
`

export const TextBold = styled.div`
  display: flex;
  align-items: center;
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${({ isAction }) => (isAction ? Colors.pink : Colors.white100)};
  cursor: ${({ isAction }) => (isAction ? 'pointer' : 'auto')};
  svg {
    margin-right: 6px;
  }
`

export const StepHeader = ({
  title,
  subLabel,
  subValue,
  onTitleClick,
}: {
  title: string,
  subLabel: string,
  subValue: string,
  onTitleClick?: () => void,
}) => (
  <HeaderWrapper>
    <SubTitle>
      {subLabel}
      <span>{subValue}</span>
    </SubTitle>
    <TextBold isAction={!!onTitleClick} onClick={onTitleClick}>
      {!!onTitleClick && <ClockSvg />} {title}
    </TextBold>
    <Separator />
  </HeaderWrapper>
)

export const ModalHeader = ({
  title,
  subLabel,
}: {
  title: string,
  subLabel: string,
}) => (
  <React.Fragment>
    <SubTitle>{subLabel}</SubTitle>
    <TextBold>{title}</TextBold>
    <Separator />
  </React.Fragment>
)

const Details = {
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Child: styled.div`
    display: flex;
    align-items: center;
    min-width: 46px;
    width: max-content;

    font-family: ${RobotoMedium.fontFamily};
    font-size: 12px;
    font-weight: ${RobotoMedium.fontWeight};
    line-height: 14px;
    color: ${Colors.white50};

    svg {
      margin-right: 4px;
    }
  `,
}

export const getErrorByField = (
  errors: { field: string, message: string }[],
  source: string,
): ?{ field: string, message: string } => {
  return errors.find(oneError => oneError.field === source)
}

export const emailMatch = (email: string): boolean => {
  const reg = /\S+@\S+\.\S+/
  return reg.test(email)
}

export const timeLeft = (hours: number): string => {
  return hours < 24
    ? `${hours} ${i18n.t('hours')}`
    : `${Math.round(hours / 24)} ${i18n.t('days')}`
}

export const getStatusColor = (value: ?string, hovered?: boolean) => {
  let color = Colors.white10

  if (value === 'unavailable') {
    color = hovered ? Colors.greyLight : Colors.grey
  } else if (value === 'available') {
    color = hovered ? Colors.blueLight : Colors.blue
  } else if (value === 'optioned') {
    color = hovered ? Colors.pinkLight : Colors.pink
  } else if (value === 'booked') {
    color = hovered ? Colors.yellowLight : Colors.yellow
  } else if (value === 'sold') {
    color = hovered ? Colors.greenLight : Colors.green
  }
  return color
}

export const getPriceWithVat = (
  pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[],
  displayedVat: ?number,
) => {
  if (!displayedVat) {
    return pricesIncludingVAT[0].amountIncludingVAT
  }
  const price = pricesIncludingVAT.find(
    price => price.vatValueInPercent === displayedVat,
  )
  if (!price) {
    console.warn('Displayed Vat not match with lot price grid')
    return pricesIncludingVAT[0].amountIncludingVAT
  }
  return price.amountIncludingVAT
}

export const LotDetails = ({
  className,
  typology,
  area,
  orientation,
  floorLabel,
}: {
  className?: string,
  typology: string,
  area: number,
  orientation: ?string,
  floorLabel: string,
}) => {
  const { t } = useTranslation()
  return (
    <Details.Row className={className}>
      <Details.Child>
        <TypologySvg />
        {t(`typology.${typology}.short`)}
      </Details.Child>
      <Details.Child>
        <AreaSvg />
        {`${Math.round(area)}m²`}
      </Details.Child>
      {orientation && (
        <Details.Child>
          <OrientationSvg />
          {t(`orientation.${orientation}.short`)}
        </Details.Child>
      )}
      <Details.Child>
        <FloorSvg />
        {t(`floor.${floorLabel}.short`)}
      </Details.Child>
    </Details.Row>
  )
}
