// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../RootStore'
import Lot, { type LotStatus } from '../Domain/Lot'
import FilterSet, { type FilterDescription } from '../Domain/Filter/FilterSet'
import { lotFilterSetBuilder } from '../Domain/lotFilterSetBuilder'
import CommercialOffer, { type LotLoan } from '../Domain/CommercialOffer'
import Customer from '../Domain/Customer'
import Configuration from '../Domain/Configuration'
import { getPriceWithVat } from '../Ui/Guidelines/components/Helper'
import i18n from '../i18n'
import { v4 as uuidv4 } from 'uuid'

type Column = {|
  status: string,
  to: string[],
  sortBy: 'label' | 'status_changed',
  collapsable: boolean,
  collapseColumn?: string,
|}

type CustomerPayload = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: ?string,
  zipCode: ?string,
  street: ?string,
  city: ?string,
}

type ParkingPayload = {|
  parkingId: string,
  label: string,
  priceExcludingVAT: number,
|}

const PROCESS_WORKFLOW: {
  [status: LotStatus]: {
    steps: { type: string, value: string }[],
  },
} = {}

const COLUMNS = [
  {
    status: 'available',
    to: ['unavailable'],
    sortBy: 'label',
    collapsable: false,
  },
  {
    status: 'unavailable',
    to: ['available'],
    sortBy: 'label',
    collapsable: false,
  },
  {
    status: 'none',
    to: [],
    sortBy: 'status_changed',
    collapsable: false,
  },
]

export default class LotsStore {
  +rootStore: RootStore;
  +columns: Column[]

  @observable programId: ?string = null
  @observable lots: Lot[] = []
  @observable filterSet: FilterSet<Lot> = FilterSet.fromFilterDefinitions([])
  @observable selectedLot: ?Lot = null
  @observable lotEvents: Object = []
  @observable transitionStatus: ?LotStatus = null
  @observable currentStep: ?{
    label: string,
    type: string,
    value: string,
  } = null

  @observable parkingsList: ?(ParkingPayload[]) = null

  @observable customerSummaries: ?(CustomerPayload[]) = null
  @observable customerAssociation: ?Customer = null
  @observable displayedVat: ?number = null
  @observable commercialOffer: ?{|
    vat: number,
    loan: LotLoan,
    duration?: number,
    discount?: number,
    parkings: ParkingPayload[],
    notaryFeeIncluded: boolean,
    totalPriceIncludingVAT: number,
    lastAction: {| author: string, date: string |},
    configuration: ?{|
      configurationId: string,
      configurationOptionPrice: number,
      receiptUrl: string,
    |},
  |} = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.columns = COLUMNS
  }

  @action async fetchLotsByProgram(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots`,
    )

    runInAction(() => {
      this.programId = programId
      this.lots = response.data.map(
        oneLotPayload =>
          new Lot(
            oneLotPayload.lotId,
            oneLotPayload.status,
            new Date(oneLotPayload.statusChangedAt),
            oneLotPayload.label,
            oneLotPayload.pricesIncludingVAT,
            oneLotPayload.orientation,
            oneLotPayload.area,
            oneLotPayload.option ? oneLotPayload.option.balcony : null,
            oneLotPayload.option ? oneLotPayload.option.garden : null,
            oneLotPayload.typology,
            oneLotPayload.floorOrder,
            oneLotPayload.floorLabel,
            oneLotPayload.buildingId,
            oneLotPayload.buildingLabel,
            oneLotPayload.plan,
            oneLotPayload.commercialOffer
              ? new CommercialOffer(
                  oneLotPayload.commercialOffer.vat,
                  oneLotPayload.commercialOffer.loan,
                  oneLotPayload.commercialOffer.customer
                    ? new Customer(
                        oneLotPayload.commercialOffer.customer.customerId,
                        oneLotPayload.commercialOffer.customer.firstName,
                        oneLotPayload.commercialOffer.customer.lastName,
                        oneLotPayload.commercialOffer.customer.email,
                        oneLotPayload.commercialOffer.customer.phone,
                        oneLotPayload.commercialOffer.customer.zipCode,
                        oneLotPayload.commercialOffer.customer.street,
                        oneLotPayload.commercialOffer.customer.city,
                      )
                    : null,
                  oneLotPayload.commercialOffer.lotPriceIncludingVAT,
                  oneLotPayload.commercialOffer.configuration
                    ? new Configuration(
                        oneLotPayload.commercialOffer.configuration.configurationId,
                        oneLotPayload.commercialOffer.configuration.configurationOptionPrice,
                        oneLotPayload.commercialOffer.configuration.receiptUrl,
                      )
                    : null,
                  oneLotPayload.commercialOffer.totalPriceIncludingVAT,
                  oneLotPayload.commercialOffer.duration || null,
                  oneLotPayload.commercialOffer.discount || null,
                  oneLotPayload.commercialOffer.notaryFeeIncluded,
                  oneLotPayload.commercialOffer.lastAction,
                  oneLotPayload.commercialOffer.priceDetails,
                )
              : null,
            oneLotPayload.duplex,
            oneLotPayload.parkings,
            oneLotPayload.links,
          ),
      )

      this.resetFilterSet()
    })
  }

  @action async updateLotStatus(
    lotId: string,
    status: LotStatus,
    updatedDuration?: boolean,
  ) {
    const lot = this.findLotById(lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lotId}/status`,
      {
        status: status,
        commercialOffer: this.commercialOffer,
        customer: this.customerAssociation,
      },
    )

    runInAction(() => {
      lot.updateStatus(status)
      if (this.commercialOffer) {
        lot.updateCommercialOffer(
          this.commercialOffer,
          this.customerAssociation,
          updatedDuration,
        )
        const currentCustomer = this.customerAssociation
        if (currentCustomer) {
          this.lots.forEach(oneLot => {
            if (
              oneLot.commercialOffer &&
              oneLot.commercialOffer.customer &&
              oneLot.commercialOffer.customer.customerId ===
                currentCustomer.customerId
            ) {
              oneLot.updateCustomer(currentCustomer)
            }
          })
          this.customerSummaries =
            this.customerSummaries &&
            this.customerSummaries.reduce((acc, oneCustomer) => {
              if (
                currentCustomer.customerId &&
                currentCustomer.customerId === oneCustomer.customerId
              ) {
                oneCustomer = {
                  customerId: currentCustomer.customerId,
                  firstName: currentCustomer.firstName,
                  lastName: currentCustomer.lastName,
                  email: currentCustomer.email,
                  phone: currentCustomer.phone,
                  zipCode: currentCustomer.zipCode,
                  street: currentCustomer.street,
                  city: currentCustomer.city,
                }
              }
              acc.push(oneCustomer)
              return acc
            }, [])
        }
      }
      this.currentStep = null
    })
  }

  @action async fetchHistory(lotId: string) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/lot/${lotId}/history`,
    )

    const eventTypes = [
      'lot_created_history',
      'lot_price_updated_history',
      'lot_status_updated_history',
      'lot_contact_request_history',
    ]
    const events = response.data.filter(oneEvent =>
      eventTypes.includes(oneEvent.type),
    )

    runInAction(() => {
      this.lotEvents = events
    })
  }

  /* @action async pingConfiguration(lot: Lot) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const updatedLot = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/lots/${lot.lotId}`,
    )

    const configurationId =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.configurationId
    const configurationOptionPrice =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.configurationOptionPrice
    const receiptUrl =
      updatedLot.data.commercialOffer &&
      updatedLot.data.commercialOffer.configuration &&
      updatedLot.data.commercialOffer.configuration.receiptUrl

    if (
      !configurationId ||
      typeof configurationOptionPrice !== 'number' ||
      !receiptUrl
    ) {
      setTimeout(() => {
        this.pingConfiguration(lot)
      }, 5000)
    } else {
      runInAction(() => {
        lot.updateConfiguration(
          configurationId,
          configurationOptionPrice,
          receiptUrl,
        )
      })
    }
  }
 */

  @action setDisplayedVat(vat: ?number) {
    this.displayedVat = vat
  }

  @action resetFilterSet() {
    const programId = this.programId
    if (!programId) {
      throw new Error('No program id linked to this store')
    }

    const program = this.rootStore.programsStore.getProgram(programId)

    this.filterSet = lotFilterSetBuilder(this.lots, program)
  }

  @action updateFilters(id: string, value: any) {
    this.filterSet = this.filterSet.toogleFilterValue(id, value)
  }

  @computed get filters(): FilterDescription[] {
    return this.filterSet.describe()
  }

  @computed get countActiveFilters() {
    return this.filterSet.countActiveFilters()
  }

  @computed get atLeastOneFilterIsActive() {
    return this.filterSet.atLeastOneFilterIsActive()
  }

  @computed get visibleLots(): Lot[] {
    return this.filterSet.filter(this.lots)
  }

  @computed get lotsByStatus(): { [keys: string]: Lot[] } {
    const lotsByStatus = {}

    this.columns.forEach(({ status, sortBy }) => {
      lotsByStatus[status] = this.visibleLots.filter(
        oneLot => oneLot.status === status,
      )
    })

    return lotsByStatus
  }

  @computed get parkingsPriceExcludingVAT(): number {
    if (!this.commercialOffer) {
      throw new Error('No commercial offer')
    }

    return this.commercialOffer.parkings.reduce((acc, oneParking) => {
      return acc + oneParking.priceExcludingVAT
    }, 0)
  }

  @computed get parkingsAvailable(): ParkingPayload[] {
    if (!this.selectedLot) {
      throw new Error('No lot current associating')
    }

    const commercialOffer = this.commercialOffer
    const parkingsList = this.parkingsList

    if (commercialOffer && parkingsList) {
      const allParkings = [...parkingsList, ...this.selectedLot.parkings].sort(
        (a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      )
      const commercialOfferParkingsId = commercialOffer.parkings.map(
        oneParking => oneParking.parkingId,
      )
      return allParkings.filter(
        oneParking => !commercialOfferParkingsId.includes(oneParking.parkingId),
      )
    }
    return this.parkingsList || []
  }

  @computed get customerAssociationPayload(): ?{
    firstName: string,
    lastName: ?string,
    email: string,
    phone: ?string,
    zipCode: ?string,
    street: ?string,
    city: ?string,
  } {
    return this.customerAssociation
      ? {
          firstName: this.customerAssociation.firstName,
          lastName: this.customerAssociation.lastName,
          email: this.customerAssociation.email,
          phone: this.customerAssociation.phone,
          zipCode: this.customerAssociation.zipCode,
          street: this.customerAssociation.street,
          city: this.customerAssociation.city,
        }
      : null
  }

  @action lotsByVat(lots: Lot[]): Lot[] {
    if (this.displayedVat) {
      return lots.filter(oneLot =>
        oneLot.pricesIncludingVAT.some(
          onePrice => onePrice.vatValueInPercent === this.displayedVat,
        ),
      )
    }
    return lots
  }

  findLotById = (lotId: string): Lot => {
    const lot = this.lots.find(lot => lot.is(lotId))
    if (!lot) {
      throw new Error(`Lot "${lotId}" not found`)
    }

    return lot
  }

  @action openLotModal(
    lotId: string,
    status: LotStatus,
    isTransition: boolean,
  ) {
    this.selectedLot = this.findLotById(lotId)

    if (!this.customerSummaries) {
      this.fetchCustomers()
    }

    if (!this.parkingsList) {
      this.fetchParkings()
    }

    const commercialOffer = this.selectedLot.commercialOffer
    if (commercialOffer) {
      this.commercialOffer = {
        vat: commercialOffer.vat,
        loan: commercialOffer.loan,
        duration: commercialOffer.duration || undefined,
        discount: commercialOffer.discount || undefined,
        parkings: [...this.selectedLot.parkings],
        notaryFeeIncluded: commercialOffer.notaryFeeIncluded,
        totalPriceIncludingVAT: commercialOffer.totalPriceIncludingVAT,
        lastAction: commercialOffer.lastAction,
        configuration: commercialOffer.clientConfiguration,
      }
      if (commercialOffer.customer) {
        this.customerAssociation = new Customer(
          commercialOffer.customer.customerId,
          commercialOffer.customer.firstName,
          commercialOffer.customer.lastName,
          commercialOffer.customer.email,
          commercialOffer.customer.phone,
          commercialOffer.customer.zipCode,
          commercialOffer.customer.street,
          commercialOffer.customer.city,
        )
      }
    } else {
      this.commercialOffer = {
        vat:
          this.displayedVat &&
          this.selectedLot.vatAvailable.includes(this.displayedVat)
            ? this.displayedVat
            : this.selectedLot.vatAvailable[0],
        loan: 'no_informations',
        parkings: [...this.selectedLot.parkings],
        notaryFeeIncluded: false,
        totalPriceIncludingVAT: 0,
        lastAction: {
          author: i18n.t('process.author'),
          date: new Date().toString(),
        },
        configuration: null,
      }
    }

    this.transitionStatus = status
    if (isTransition) {
      const firstStep = this.customerAssociation
        ? { ...PROCESS_WORKFLOW[status].steps[1] }
        : { ...PROCESS_WORKFLOW[status].steps[0] }
      this.currentStep = PROCESS_WORKFLOW[status]
        ? {
            ...firstStep,
            label: i18n.t(`process.${status}`),
          }
        : null
    }
  }

  @action closeLotModal() {
    this.selectedLot = null
    this.currentStep = null
    this.transitionStatus = null
    this.commercialOffer = null
    this.customerAssociation = null
    this.parkingsList = null
    this.lotEvents = []
  }

  @action goToStep(stepType: string) {
    const transitionStatus = this.transitionStatus
    if (!transitionStatus) {
      throw new Error('No current transition status')
    }

    const nextStep = PROCESS_WORKFLOW[transitionStatus].steps.find(
      oneStep => oneStep.type === stepType,
    )
    if (!nextStep) {
      throw new Error(`Unable go to step: "${stepType}"`)
    }

    if (stepType === 'customer_selection') {
      this.customerAssociation = null
    }

    this.currentStep = {
      ...nextStep,
      label: i18n.t(`process.${transitionStatus}`),
    }
  }

  @action async fetchCustomers() {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const customersResponse =
      await this.rootStore.authenticationStore.httpClient.get(
        `/api/seller/program/${this.programId}/customers/summaries`,
      )

    runInAction(() => {
      this.customerSummaries = customersResponse.data
    })
  }

  @action async fetchParkings() {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const parkingsResponse =
      await this.rootStore.authenticationStore.httpClient.get(
        `/api/seller/program/${this.programId}/parkings/list`,
      )

    runInAction(() => {
      this.parkingsList = parkingsResponse.data
    })
  }

  @action selectCustomerAssociation(customerId: string) {
    if (!this.customerSummaries) {
      throw new Error('No customer summaries linked to this store')
    }

    const customer = this.customerSummaries.find(
      oneCustomer => oneCustomer.customerId === customerId,
    )

    if (!customer) {
      throw new Error(`Unable find customer with id: "${customerId}"`)
    }

    this.customerAssociation = new Customer(
      customer.customerId,
      customer.firstName,
      customer.lastName,
      customer.email,
      customer.phone,
      customer.zipCode,
      customer.street,
      customer.city,
    )

    this.goToStep('customer_creation')
  }

  @action creationCustomerAssociation(customer: {
    firstNameValue: string,
    lastNameValue: string,
    emailValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) {
    this.customerAssociation = new Customer(
      (this.customerAssociation && this.customerAssociation.customerId) ||
        uuidv4(),
      customer.firstNameValue,
      customer.lastNameValue,
      customer.emailValue,
      customer.phoneValue,
      customer.addressValue,
      customer.zipCodeValue,
      customer.cityValue,
    )

    this.goToStep('customer_funding')
  }

  @action fundingSelection(loan: LotLoan) {
    if (!this.commercialOffer) {
      throw new Error('No commercial offer')
    }

    this.commercialOffer.loan = loan
  }

  @action onStatusChange(lotId: string, status: LotStatus) {
    if (status === 'available' || status === 'unavailable') {
      this.commercialOffer = null
      this.customerAssociation = null
      this.updateLotStatus(lotId, status)
    } else {
      this.openLotModal(lotId, status, true)
    }
  }

  @action onOptioning(vat: number, duration: number) {
    const selectedLot = this.selectedLot
    if (!selectedLot) {
      throw new Error('No lot current associating')
    }
    const commercialOffer = this.commercialOffer
    if (!commercialOffer) {
      throw new Error('No commercial offer')
    }
    commercialOffer.vat = vat
    commercialOffer.duration = duration * 24
    commercialOffer.lastAction = {
      author: i18n.t('process.author'),
      date: new Date().toString(),
    }
    commercialOffer.totalPriceIncludingVAT = getPriceWithVat(
      selectedLot.pricesIncludingVAT,
      vat,
    )

    this.updateLotStatus(selectedLot.lotId, 'optioned', true)
  }

  @action onParkingSelected(parking: ParkingPayload) {
    const commercialOffer = this.commercialOffer
    if (!commercialOffer) {
      throw new Error('No commercial offer')
    }

    if (
      !commercialOffer.parkings.some(
        oneParking => oneParking.parkingId === parking.parkingId,
      )
    ) {
      commercialOffer.parkings.push({
        parkingId: parking.parkingId,
        label: parking.label,
        priceExcludingVAT: parking.priceExcludingVAT,
      })
      this.commercialOffer = { ...commercialOffer }
    }
  }

  @action onParkingRemoved(parkingId: string) {
    const commercialOffer = this.commercialOffer
    if (!commercialOffer) {
      throw new Error('No commercial offer')
    }

    const indexParking = commercialOffer.parkings.findIndex(
      oneParking => oneParking.parkingId === parkingId,
    )

    if (indexParking > -1) {
      commercialOffer.parkings.splice(indexParking, 1)
      this.commercialOffer = { ...commercialOffer }
    }
  }

  @action applyCommercialOffer({
    vat,
    discount,
    notaryFeeIncluded,
    totalPriceIncludingVAT,
  }: {
    vat: number,
    discount: number,
    notaryFeeIncluded: boolean,
    totalPriceIncludingVAT: number,
  }) {
    const selectedLot = this.selectedLot
    if (!selectedLot) {
      throw new Error('No lot current associating')
    }
    const commercialOffer = this.commercialOffer
    if (!commercialOffer) {
      throw new Error('No commercial offer')
    }
    const transitionStatus = this.transitionStatus
    if (!transitionStatus) {
      throw new Error('No current transition status')
    }

    commercialOffer.vat = vat
    commercialOffer.discount = discount
    commercialOffer.notaryFeeIncluded = notaryFeeIncluded
    commercialOffer.duration = undefined
    commercialOffer.totalPriceIncludingVAT = totalPriceIncludingVAT - discount
    commercialOffer.lastAction = {
      author: i18n.t('process.author'),
      date: new Date().toString(),
    }

    this.updateLotStatus(selectedLot.lotId, transitionStatus)
  }

  _sortLotsByLabel(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.label < lotB.label ? -1 : 1
  }

  _sortLotsByStatusChanged(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.statusChangedAt > lotB.statusChangedAt ? -1 : 1
  }
}
