// @flow

import { computed, observable } from 'mobx'
import CommercialOffer, { type LotLoan } from './CommercialOffer'
import Customer from './Customer'

export type LotStatus =
  | 'unavailable'
  | 'available'
  | 'optioned'
  | 'booked'
  | 'sold'

export default class Lot {
  +lotId: string;
  +label: string;
  +orientation: ?string;
  +area: number;
  +balconyArea: ?number;
  +gardenArea: ?number;
  +typology: string;
  +floorOrder: number;
  +floorLabel: string;
  +buildingId: string;
  +buildingLabel: string;
  +planUrl: string;
  +duplex: {| floor: number, area: number |}[];
  +pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[];

  +links: {
    plan: string,
    configurator?: string,
    virtual_tour?: string,
  }

  @observable parkings: {|
    parkingId: string,
    label: string,
    priceExcludingVAT: number,
  |}[]

  @observable status: LotStatus = 'available'
  @observable statusChangedAt: Date = new Date()
  @observable commercialOffer: ?CommercialOffer = null

  constructor(
    lotId: string,
    status: LotStatus,
    statusChangedAt: Date,
    label: string,
    pricesIncludingVAT: {
      amountIncludingVAT: number,
      vatValueInPercent: number,
    }[],
    orientation: ?string,
    area: number,
    balconyArea: ?number,
    gardenArea: ?number,
    typology: string,
    floorOrder: number,
    floorLabel: string,
    buildingId: string,
    buildingLabel: string,
    planUrl: string,
    commercialOffer: ?CommercialOffer,
    duplex: {| floor: number, area: number |}[],
    parkings: {|
      parkingId: string,
      label: string,
      priceExcludingVAT: number,
    |}[],
    links: {
      plan: string,
      configurator?: string,
      virtual_tour?: string,
    },
  ) {
    this.lotId = lotId
    this.status = status
    this.statusChangedAt = statusChangedAt
    this.label = label
    this.pricesIncludingVAT = pricesIncludingVAT
    this.orientation = orientation
    this.area = area
    this.balconyArea = balconyArea
    this.gardenArea = gardenArea
    this.typology = typology
    this.floorOrder = floorOrder
    this.floorLabel = floorLabel
    this.buildingId = buildingId
    this.buildingLabel = buildingLabel
    this.planUrl = planUrl
    this.commercialOffer = commercialOffer
    this.duplex = duplex
    this.parkings = parkings
    this.links = links
  }

  is(lookupId: string): boolean {
    return this.lotId === lookupId
  }

  _updateChangedAt(): void {
    const now = new Date()
    this.statusChangedAt = now
  }

  updateStatus(status: LotStatus): void {
    this.status = status
    this._updateChangedAt()

    if (
      (status === 'available' || status === 'unavailable') &&
      this.commercialOffer
    ) {
      this.commercialOffer = null
    }
  }

  updateCommercialOffer(
    commercialOffer: {|
      vat: number,
      loan: LotLoan,
      duration?: number,
      discount?: number,
      parkings: {|
        parkingId: string,
        label: string,
        priceExcludingVAT: number,
      |}[],
      notaryFeeIncluded: boolean,
      totalPriceIncludingVAT: number,
      lastAction: {| author: string, date: string |},
      configuration: ?{|
        configurationId: string,
        configurationOptionPrice: number,
        receiptUrl: string,
      |},
    |},
    customer: ?Customer,
    updatedDuration?: boolean,
  ): void {
    if (!this.commercialOffer) {
      let lotPriceInCurrentVat = this.pricesIncludingVAT[0].amountIncludingVAT
      const lotInCurrentVat = this.pricesIncludingVAT.find(
        onePrice => onePrice.vatValueInPercent === commercialOffer.vat,
      )
      if (lotInCurrentVat) {
        lotPriceInCurrentVat = lotInCurrentVat.amountIncludingVAT
      }
      this.commercialOffer = new CommercialOffer(
        commercialOffer.vat,
        commercialOffer.loan,
        customer,
        lotPriceInCurrentVat,
        null,
        commercialOffer.totalPriceIncludingVAT,
        commercialOffer.duration || null,
        commercialOffer.discount || null,
        commercialOffer.notaryFeeIncluded,
        commercialOffer.lastAction,
        null,
      )
    } else {
      this.commercialOffer.update(commercialOffer, customer, updatedDuration)
    }
    if (commercialOffer.parkings) {
      this.parkings = commercialOffer.parkings
    }
  }

  updateCustomer(customer: Customer): void {
    this.commercialOffer && this.commercialOffer.updateCustomer(customer)
  }

  /* updateConfiguration(
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  ): void {
    if (!this.commercialOffer) {
      throw new Error('No customer offer instantiated')
    }
    this.commercialOffer.updateConfiguration(
      configurationId,
      configurationOptionPrice,
      receiptUrl,
    )
  } */

  @computed get vatAvailable(): number[] {
    return this.pricesIncludingVAT.map(onePrice => onePrice.vatValueInPercent)
  }
}
