// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import DashedSeparator, { VerticalDashedSeparator } from '../DashedSeparator'
import Price from '../Typography/Price'
import Taxes from '../Typography/Taxes'
import OutdoorSvg from '../icons/OutdoorSvg'
import ParkingSvg from '../icons/ParkingSvg'
import DuplexSvg from '../icons/DuplexSvg'
import SwitchSvg from '../icons/SwitchSvg'
import BackwardSvg from '../icons/BackwardSvg'
import {
  LotDetails,
  getPriceWithVat,
  TextNormal,
  TextBold,
  SubTitle,
  LotHistoryButton,
  getStatusColor,
} from './Helper'
import { ButtonValideStatus } from './Button'
import ArrowDownSvg from '../icons/ArrowDownSvg'
import History from './History'
import LinkToSvg from '../icons/LinkToSvg'
import ExploreSvg from '../icons/ExploreSvg'
import ManagerLogoSvg from '../icons/ManagerLogoSvg'

const statusArray = ['unavailable', 'available']

const Header = styled.div`
  display: flex;
  height: 40px;
`

const LinksContainer = styled.div`
  margin-left: 4px;
  height: 19px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'none' : 'all')};

  svg {
    cursor: pointer;
    circle {
      transition: fill 0.15s ease-in-out;
    }
    &:hover circle {
      fill: ${Colors.blueLight};
    }
  }
  position: relative;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

const VerticalSeparator = styled(VerticalDashedSeparator)`
  margin: 0 20px;
`

const HorizontalSeparator = styled(DashedSeparator)`
  margin: 20px 0;
`

const OptionContainer = styled(TextNormal)`
  display: flex;
  align-items: center;
  div {
    color: ${Colors.white30};
    margin-left: 10px;
  }
  span {
    color: ${Colors.white50};
  }
`

const Pricing = styled.div`
  cursor: pointer;
  pointer-events: ${({ onlyOneVat }) => (onlyOneVat ? 'none' : 'auto')};
`

const ButtonContainer = styled.div`
  width: 300px;
  position: absolute;
  bottom: 30px;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 30px;
  top: 0;
  left: 0;
  width: 360px;
  height: 100%;
  background-color: ${Colors.brown1};
  z-index: 20;
`

const StatusLabel = styled(TextBold)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  flex: 1;
  text-align: center;
  transition: color 0.25s ease-in-out;
  color: ${({ isActive, isDisabled, color }) =>
    isActive ? color : isDisabled ? Colors.white10 : Colors.white50};
  pointer-events: ${({ isActive, isDisabled }) =>
    isActive || isDisabled ? 'none' : 'initial'};

  &:hover {
    color: ${Colors.white100};
  }
`

const DropdownContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  border: solid 1px ${Colors.black10};
  background-color: ${Colors.carbon};
  padding: 10px 15px;
  width: max-content;
  position: absolute;
  z-index: 1;
  top: 23px;
  left: 0px;
  pointer-events: all;

  ${TextNormal} {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${Colors.white30};
    text-decoration: none;
    transition: color 0.15s ease-in-out;
    padding: 5px 0;

    svg {
      margin-right: 4px;
    }
    path {
      transition: fill-opacity 0.15s ease-in-out;
    }

    &:hover {
      color: ${Colors.white100};
      path {
        fill-opacity: 1;
      }
    }
  }
`

const ComingSoon = styled.div`
  height: 290px;
  width: 245px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.3);
  font-family: ${RobotoMedium.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 20px;

  div {
    margin-bottom: 12px;
  }
`

export default ({
  label,
  pricesIncludingVAT,
  typology,
  area,
  balconyArea,
  gardenArea,
  orientation,
  floorLabel,
  buildingLabel,
  displayedVat,
  parkings,
  duplex,
  status,
  statusAllow,
  requestStatusChange,
  inTransition,
  events,
  links,
  fetchHistory,
  markAsContacted,
}: {|
  label: string,
  pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[],
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: ?string,
  floorLabel: string,
  buildingLabel: string,
  displayedVat: ?number,
  parkings: {| parkingId: string, label: string, priceExcludingVAT: number |}[],
  duplex: {| floor: number, area: number |}[],
  status: string,
  statusAllow: string[],
  requestStatusChange: (
    status: 'unavailable' | 'available' | 'optioned' | 'booked' | 'sold',
  ) => void,
  inTransition: boolean,
  events: {|
    type: string,
    date: string,
    author: {
      type: string,
      details?: {
        firstName: string,
        lastName: string,
        email?: string,
        phone?: string,
        address?: string,
        zipcode?: string,
        city?: string,
      },
    },
    data: ?{
      prices?: { vat: number, oldPrice: number, newPrice: number }[],
      contactRequestId?: string,
      contactedAt?: string,
      newStatus?: string,
      label?: string,
    },
  |}[],
  links: {
    virtual_tour?: string,
    plan: string,
    configurator?: string,
  },
  fetchHistory: () => Promise<void>,
  markAsContacted: (contactRequestId: string) => Promise<void>,
|}) => {
  const { t } = useTranslation()

  const _selectionRef = React.useRef()
  const _dropdownRef = React.useRef()
  const [currentVat, setCurrentVat] = React.useState(
    displayedVat || pricesIncludingVAT[0].vatValueInPercent,
  )
  const [dropdownLinksIsOpen, setDropdownLinksIsOpen] = React.useState(false)
  const [statusSelectionIsOpen, setStatusSelectionIsOpen] =
    React.useState(false)
  const [displayHistory, setDisplayHistory] = React.useState(false)

  const handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (
      dropdownLinksIsOpen &&
      _dropdownRef.current &&
      !_dropdownRef.current.contains(event.target)
    ) {
      setDropdownLinksIsOpen(false)
    } else if (
      statusSelectionIsOpen &&
      _selectionRef.current &&
      !_selectionRef.current.contains(event.target)
    ) {
      setStatusSelectionIsOpen(false)
    }
  }

  React.useEffect(() => {
    window.document.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const switchVat = () => {
    const index = pricesIncludingVAT.findIndex(
      price => price.vatValueInPercent === currentVat,
    )
    if (index + 1 < pricesIncludingVAT.length) {
      setCurrentVat(pricesIncludingVAT[index + 1].vatValueInPercent)
    } else {
      setCurrentVat(pricesIncludingVAT[0].vatValueInPercent)
    }
  }

  return (
    <React.Fragment>
      <LotHistoryButton
        displayHistory={displayHistory}
        onClick={() => {
          !displayHistory && events.length === 0 && fetchHistory()
          setDisplayHistory(!displayHistory)
        }}
      >
        <BackwardSvg />
      </LotHistoryButton>

      <Header>
        <div>
          {buildingLabel && <SubTitle>{buildingLabel}</SubTitle>}
          <LabelWrapper>
            <TextBold>{label}</TextBold>
            {Object.keys(links).length > 1 ? (
              <LinksContainer
                isOpen={dropdownLinksIsOpen}
                onClick={() => setDropdownLinksIsOpen(!dropdownLinksIsOpen)}
              >
                <ExploreSvg />
                {dropdownLinksIsOpen && (
                  <DropdownContainer ref={_dropdownRef}>
                    <TextNormal as="a" target="_blank" href={links.plan}>
                      <LinkToSvg /> {t('lot.salesPlan')}
                    </TextNormal>
                    {(links.configurator || links.virtual_tour) && (
                      <TextNormal
                        as="a"
                        target="_blank"
                        href={links.configurator || links.virtual_tour}
                      >
                        <LinkToSvg /> Indoor
                      </TextNormal>
                    )}
                  </DropdownContainer>
                )}
              </LinksContainer>
            ) : (
              <LinksContainer as="a" target="_blank" href={links.plan}>
                <ExploreSvg />
              </LinksContainer>
            )}
          </LabelWrapper>
        </div>
        <VerticalSeparator />
        <Pricing
          onlyOneVat={pricesIncludingVAT.length === 1}
          onClick={() => switchVat()}
        >
          <SubTitle>
            <Taxes amount={currentVat} />
            {pricesIncludingVAT.length > 1 && <SwitchSvg />}
          </SubTitle>
          <TextBold>
            <Price amount={getPriceWithVat(pricesIncludingVAT, currentVat)} />
          </TextBold>
        </Pricing>
      </Header>

      <HorizontalSeparator />

      {displayHistory ? (
        <History events={events} markAsContacted={markAsContacted} />
      ) : (
        <React.Fragment>
          <LotDetails
            typology={typology}
            area={area}
            orientation={orientation}
            floorLabel={floorLabel}
          />

          {duplex.length > 0 && (
            <React.Fragment>
              <HorizontalSeparator />
              <OptionContainer>
                <DuplexSvg />
                {duplex.map((elem, index) => (
                  <div key={index}>
                    {t('lot.floorShort')}
                    {elem.floor} <span>{elem.area}m²</span>
                  </div>
                ))}
              </OptionContainer>
            </React.Fragment>
          )}

          {parkings.length > 0 && (
            <React.Fragment>
              <HorizontalSeparator />
              <OptionContainer>
                <ParkingSvg />
                {parkings.map((elem, index) => (
                  <div key={index}>
                    {t('lot.parking')} <span>{elem.label}</span>
                  </div>
                ))}
              </OptionContainer>
            </React.Fragment>
          )}

          {(balconyArea || gardenArea) && (
            <React.Fragment>
              <HorizontalSeparator />
              <OptionContainer>
                <OutdoorSvg />
                {balconyArea && (
                  <div>
                    {t('lot.balcony')} <span>{balconyArea}m²</span>
                  </div>
                )}
                {gardenArea && (
                  <div>
                    {t('lot.garden')} <span>{gardenArea}m²</span>
                  </div>
                )}
              </OptionContainer>
            </React.Fragment>
          )}

          {!inTransition && (
            <ButtonContainer>
              <ButtonValideStatus
                color={getStatusColor(status)}
                colorHover={getStatusColor(status, true)}
                onClick={() => setStatusSelectionIsOpen(true)}
              >
                {t(`status.${status}`)}
                <ArrowDownSvg />
              </ButtonValideStatus>
            </ButtonContainer>
          )}

          {statusSelectionIsOpen && (
            <StatusContainer ref={_selectionRef}>
              {statusArray.map((oneStatus, index) => (
                <React.Fragment key={oneStatus}>
                  <StatusLabel
                    color={getStatusColor(oneStatus)}
                    onClick={() => {
                      setStatusSelectionIsOpen(false)
                      requestStatusChange(oneStatus)
                    }}
                    isActive={status === oneStatus}
                    isDisabled={!statusAllow.includes(oneStatus)}
                  >
                    {t(`status.${oneStatus}`)}
                  </StatusLabel>
                  {index !== statusArray.length - 1 && <DashedSeparator />}
                </React.Fragment>
              ))}
              <DashedSeparator />
              <ComingSoon>
                <div>{t('comingSoon')}</div>
                <ManagerLogoSvg />
              </ComingSoon>
            </StatusContainer>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
