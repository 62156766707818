// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import { RobotoMedium, RobotoBold } from '../../Typography/Fonts'
import Price from '../../Typography/Price'
import Taxes from '../../Typography/Taxes'
import OutdoorSvg from '../../icons/OutdoorSvg'
import ParkingSvg from '../../icons/ParkingSvg'
import DuplexSvg from '../../icons/DuplexSvg'
import SmallStarSvg from '../../icons/SmallStarFilledSvg'
import SwitchSvg from '../../icons/SwitchSvg'
import Tooltip from '../Tooltip'
import {
  LotDetails,
  getPriceWithVat,
  TextNormal,
  getStatusColor,
  timeLeft,
} from '../Helper'

const LotCardContainer = styled.div`
  position: relative;
  width: 280px;
  height: 128px;
  border-radius: 8px;
  margin: 0 8px 8px 8px;
  box-shadow: ${({ isDragging }) =>
    isDragging ? `0 0 8px 0 ${Colors.black50}` : `0 0 4px 0 ${Colors.black50}`};
  overflow: hidden;
`

const TextBold = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  color: ${Colors.white100};
`

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  height: 50%;
  background-color: ${Colors.dark2};
`

const DetailsContainer = styled(LotDetails)`
  padding: 0px 16px;
  height: 50%;
  border-radius: 0 0 8px 8px;
  background-color: ${Colors.brown1};
  box-shadow: inset 0 -2px 0 0 ${Colors.brown2},
    inset 0 1px 0 0 ${Colors.black10};
`

const Label = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`

const TimeWrapper = styled.span`
  border-radius: 11px;
  background-color: ${Colors.brown1};
  font-family: ${RobotoBold.fontFamily};
  font-size: 9px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 10px;
  padding: 2px 5px;
  width: max-content;
  margin-left: 4px;
`

const CommercialOffer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 11px;
  background-color: ${({ color }) => color};
  padding: ${({ remainingTime }) =>
    remainingTime ? '2px 2px 2px 10px' : '2px 10px'};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 14px;
  width: max-content;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.brown1};
  box-sizing: content-box;

  i {
    margin-left: 3px;
  }
  svg {
    margin-left: 1px;
  }

  ${TimeWrapper} {
    color: ${({ color }) => color};
  }
`

const Vat = styled(TextNormal)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ vatIsFixed }) => (vatIsFixed ? Colors.white100 : Colors.white50)};
  pointer-events: ${({ vatIsFixed, onlyOneVat }) =>
    vatIsFixed || onlyOneVat ? 'none' : 'unset'};
  user-select: none;
`

const DuplexContent = ({
  duplex,
}: {
  duplex: { floor: number, area: number }[],
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {duplex.map((elem, index) => (
        <div
          key={index}
          style={{ marginBottom: duplex.length - 1 !== index ? '10px' : 0 }}
        >
          {t('lot.floorShort')}
          {elem.floor}{' '}
          <span style={{ color: Colors.white50 }}>{elem.area}m²</span>
        </div>
      ))}
    </React.Fragment>
  )
}

const ParkingContent = ({
  parkings,
}: {
  parkings: { parkingId: string, label: string }[],
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {parkings.map((oneParking, index) => (
        <div
          key={index}
          style={{ marginBottom: parkings.length - 1 !== index ? '10px' : 0 }}
        >
          {t('lot.parking')}{' '}
          <span style={{ color: Colors.white50 }}>{oneParking.label}</span>
        </div>
      ))}
    </React.Fragment>
  )
}

const OutdoorContent = ({
  balconyArea,
  gardenArea,
}: {
  balconyArea: ?number,
  gardenArea: ?number,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {balconyArea && (
        <div style={{ marginBottom: gardenArea ? '10px' : 0 }}>
          {t('lot.balcony')}{' '}
          <span style={{ color: Colors.white50 }}>{balconyArea}m²</span>
        </div>
      )}
      {gardenArea && (
        <div>
          {t('lot.garden')}{' '}
          <span style={{ color: Colors.white50 }}>{gardenArea}m²</span>
        </div>
      )}
    </React.Fragment>
  )
}

const PriceDetailsContent = ({
  priceDetails,
}: {
  priceDetails?: { label: string, amount: number }[],
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {priceDetails &&
        priceDetails.map((onPrice, index) => (
          <div
            key={index}
            style={{
              marginBottom: priceDetails.length - 1 !== index ? '10px' : 0,
            }}
          >
            {t(`lot.${onPrice.label}`)}{' '}
            <span style={{ color: Colors.white50 }}>
              <Price amount={onPrice.amount} />
            </span>
          </div>
        ))}
    </React.Fragment>
  )
}

type Props = {|
  lotId: string,
  status: string,
  label: string,
  pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[],
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: ?string,
  floorLabel: string,
  buildingLabel: string,
  isDragging?: boolean,
  displayedVat: number,
  commercialOffer: ?{
    firstName: ?string,
    lastName: ?string,
    funding: string,
    duration: ?number,
    price: number,
    priceDetails: ?({ label: string, amount: number }[]),
    vat: number,
  },
  parkings: { parkingId: string, label: string }[],
  duplex: { floor: number, area: number }[],
  switchVat: () => void,
|}

const Card = ({
  lotId,
  status,
  label,
  pricesIncludingVAT,
  typology,
  area,
  balconyArea,
  gardenArea,
  orientation,
  floorLabel,
  buildingLabel,
  isDragging = false,
  displayedVat,
  commercialOffer,
  parkings,
  duplex,
  switchVat,
}: Props) => {
  const color = getStatusColor(status)
  return (
    <LotCardContainer
      isDragging={isDragging}
      data-test-id={`lot-card-${lotId}`}
    >
      {commercialOffer && (
        <CommercialOffer color={color} remainingTime={commercialOffer.duration}>
          {commercialOffer.firstName && commercialOffer.lastName
            ? `${commercialOffer.firstName.charAt(0)}. ${
                // $FlowFixMe
                commercialOffer.lastName
              }`
            : '?'}
          {commercialOffer.funding === 'loan_agreed' && (
            <i>
              <SmallStarSvg />
            </i>
          )}
          {commercialOffer.funding === 'loan_granted' && (
            <i>
              <SmallStarSvg />
              <SmallStarSvg />
            </i>
          )}
          {commercialOffer.funding === 'cash_purchase' && (
            <i>
              <SmallStarSvg />
              <SmallStarSvg />
              <SmallStarSvg />
            </i>
          )}
          {commercialOffer.duration && (
            <TimeWrapper>{timeLeft(commercialOffer.duration)}</TimeWrapper>
          )}
        </CommercialOffer>
      )}
      <Main>
        <TextBold data-test-node-type="lot-card-label">
          <Label>
            {label}
            {duplex.length > 0 && (
              <Tooltip content={<DuplexContent duplex={duplex} />}>
                <DuplexSvg />
              </Tooltip>
            )}
            {parkings.length > 0 && (
              <Tooltip content={<ParkingContent parkings={parkings} />}>
                <ParkingSvg />
              </Tooltip>
            )}
            {(balconyArea || gardenArea) && (
              <Tooltip
                content={
                  <OutdoorContent
                    balconyArea={balconyArea}
                    gardenArea={gardenArea}
                  />
                }
              >
                <OutdoorSvg />
              </Tooltip>
            )}
          </Label>
          <TextNormal>{buildingLabel}</TextNormal>
        </TextBold>
        <TextBold>
          <Tooltip
            content={
              commercialOffer && commercialOffer.priceDetails ? (
                <PriceDetailsContent
                  priceDetails={commercialOffer && commercialOffer.priceDetails}
                />
              ) : undefined
            }
          >
            <Price
              amount={
                (commercialOffer && commercialOffer.price) ||
                getPriceWithVat(pricesIncludingVAT, displayedVat)
              }
            />
          </Tooltip>
          <Vat
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              switchVat()
            }}
            vatIsFixed={commercialOffer && commercialOffer.vat}
            onlyOneVat={pricesIncludingVAT.length === 1}
          >
            <Taxes
              amount={(commercialOffer && commercialOffer.vat) || displayedVat}
            />
            {!(commercialOffer && commercialOffer.vat) &&
              pricesIncludingVAT.length > 1 && <SwitchSvg />}
          </Vat>
        </TextBold>
      </Main>
      <DetailsContainer
        typology={typology}
        area={area}
        orientation={orientation}
        floorLabel={floorLabel}
      />
    </LotCardContainer>
  )
}

export default React.memo<Props>(Card)
