// @flow

import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RobotoBold, RobotoMedium } from '../../Typography/Fonts'
import { TextBold } from '../Helper'
import { Colors } from '../../Colors'
import { CurrencyInput } from '../Form/index'
import ArrowRight from '../../icons/ArrowRight.svg'
import { LegendStatus } from './Dot'
import DashedSeparator from '../../DashedSeparator'
import ImportSvg from '../../icons/ImportSvg'
import ExportSvg from '../../icons/ExportSvg'
import AsteriskSvg from '../../icons/Asterisk.svg'
import CheckSvg from '../../icons/CheckSvg'
import WarningSvg from '../../icons/WarningSvg'

export const BackgroundContainer = styled.div`
  position: relative;
  height: calc(100% - 56px);
  overflow: auto;
  border-radius: 8px;
  background-color: ${Colors.carbon};
`

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  min-width: max-content;
  position: relative;
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  height: 100%;
  overflow: auto;
  padding: 10px;
`

export const PriceInput = styled(CurrencyInput)`
  width: calc(100% - 16px);
  margin: 0 8px;
  border-color: transparent;
  background-color: transparent;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  padding: 0;
  height: 32px;
  text-align: center;
  border-radius: 4px;
  border: ${({ isValidValue }) =>
    !isValidValue ? `1px solid ${Colors.red}` : null};
  transition: color 0.15s ease-in-out;
  color: ${({ isUnvailable, isSold }) =>
    isUnvailable ? Colors.white20 : isSold ? Colors.green75 : Colors.white100};

  &:focus {
    outline: none;
    border: 1px solid ${Colors.blue};
  }

  &:disabled {
    color: ${({ isUnvailable, isSold }) =>
      isUnvailable
        ? Colors.white20
        : isSold
        ? Colors.green75
        : Colors.white100};
  }
`

export const Table = styled.table`
  position: relative;
  width: max-content;
  height: max-content;
  max-height: 100%;
  table-layout: fixed;
  user-select: none;
  border-spacing: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 32px;
  text-align: center;
  margin-bottom: 10px;
`

export const TableForThead = styled(Table)`
  height: 14px;
  padding: 0 10px;
  line-height: 14px;
  margin-top: 13px;
  margin-bottom: 3px;
`

export const Th = styled.th`
  padding: 0;
  color: ${Colors.white30};
  font-size: 12px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
`

export const Thead = styled.thead``

export const Tbody = styled.tbody`
  background-color: ${Colors.brown1};
  box-shadow: 0px -4px 4px -4px ${Colors.black50},
    -10px -6px 4px -10px ${Colors.black50},
    10px -6px 4px -10px ${Colors.black50};
  border-radius: 8px;

  &:not(:last-child):after {
    height: 10px;
    display: table-row;
    content: ' ';
    background-color: ${Colors.carbon};
  }
`

export const Tr = styled.tr`
  white-space: nowrap;
  tbody &:last-child {
    box-shadow: 0 4px 4px -4px ${Colors.black50},
      inset 0 -2px 0 0 ${Colors.brown2};
    border-radius: 8px;
  }

  tbody &:first-child,
  tbody &:last-child {
    height: 40px;
  }

  &:first-child td:first-child {
    pointer-events: none;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: dashed black 1px;
    box-shadow: 0 4px 4px -4px ${Colors.black50}, 1px 0 0 0 ${Colors.white5};
  }

  ${({ isUnvailable }) =>
    isUnvailable &&
    css`
      &:hover {
        td span {
          color: ${Colors.white50};
        }
        td ${PriceInput}, td .trHoverWhite100 {
          color: ${Colors.white100};
        }
        td .trHoverPathWhite100 path {
          fill: ${Colors.white100};
        }
        td .trHoverPathWhite50 path {
          fill: ${Colors.white50};
        }
      }
    `}
`

export const Td = styled.td`
  position: relative;
  background-color: transparent;
  padding: 0px;

  span {
    transition: color 0.15s ease-in-out;
    color: ${({ isUnvailable, isSold }) =>
      isUnvailable ? Colors.white20 : isSold ? Colors.green75 : Colors.white50};
  }

  tbody & {
    display: table-cell;
  }

  tbody tr:first-child & {
    padding-top: ${({ rowSpan }) => (rowSpan > 1 ? 0 : '8px')};
  }

  tbody tr:last-child & {
    padding-bottom: 8px;
  }

  tbody tr:first-child &:last-child {
    border-top-right-radius: 8px;
  }

  tbody tr:last-child &:last-child {
    border-bottom-right-radius: 8px;
  }
`

export const TdInput = styled(Td)`
  display: table-cell;
`

export const ColumnLeft = styled.div`
  position: relative;
  flex: 1;
  margin: 40px 10px 0 20px;
`

export const ColumnRight = styled.div`
  position: relative;
  flex: 1;
  margin: 40px 20px 0 10px;
`

export const BuildingContainer = styled.div`
  margin: auto;
  width: max-content;
`

export const BuildingLabel = styled.div`
  cursor: pointer;
  font-family: ${RobotoBold.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 16px;
  padding-right: 14px;
  margin: 0 auto 10px auto;
  transform: ${({ isActive }) =>
    isActive ? 'translateX(14px)' : 'translateX(0)'};
  color: ${({ isActive }) => (isActive ? Colors.white100 : Colors.white30)};
  transition: color 0.25s ease-in-out, transform 0.15s ease-in-out;
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};

  &:hover {
    color: ${Colors.white100};
  }

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: -14px;
    width: 10px;
    height: 10px;

    background-image: url(${ArrowRight});
    background-size: 100% 100%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: opacity ease-in-out 0.15s;
  }
`

export const SaveContainer = styled.div`
  position: absolute;
  bottom: 40px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.white30};
  width: max-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  div {
    text-align: center;
    margin-bottom: 10px;
  }
`

export const Modified = styled.div`
  position: absolute;
  top: ${({ isFirst }) => (isFirst ? '12px' : '4px')};
  left: -12px;
  width: 24px;
  height: 24px;

  background-image: url(${AsteriskSvg});
  background-size: 100% 100%;
  opacity: ${({ isUpdated }) => (isUpdated ? 1 : 0)};
  transition: opacity ease-in-out 0.15s;
`

const LegendWrapper = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  margin: auto;
  color: ${Colors.white30};
  width: max-content;
  ${DashedSeparator} {
    margin: 20px 0;
  }
`

const LegendIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color ease-in-out 0.25s;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  path {
    transition: fill-opacity ease-in-out 0.25s;
  }

  &:hover {
    path {
      fill-opacity: 1;
    }
    color: ${Colors.white100};
  }
`

const FeedbackContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(3px);
  background-color: ${Colors.black50};
  color: ${Colors.white100};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
`

const FeedbackMessage = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
  margin-top: 10px;
`

export const Feedback = ({
  close,
  succeed,
  message,
}: {
  close: () => void,
  succeed: boolean,
  message: ?string,
}) => {
  const { t } = useTranslation()

  return (
    <FeedbackContainer onClick={() => close()}>
      {succeed ? <CheckSvg /> : <WarningSvg />}
      <TextBold>
        {t('programEdition.import')} {succeed ? 'success' : 'failed'}
      </TextBold>
      {message && <FeedbackMessage>{message}</FeedbackMessage>}
    </FeedbackContainer>
  )
}

export const Legend = ({
  onImport,
  onExport,
}: {|
  onImport: (file: string) => Promise<void>,
  onExport: () => Promise<void>,
|}) => {
  const { t } = useTranslation()

  return (
    <LegendWrapper>
      <LegendStatus />
      <DashedSeparator />
      <LegendIcon as="label" htmlFor="import">
        <input
          id="import"
          type="file"
          accept=".xlsx, .xls, .csv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: 'none', opacity: 0 }}
          onChange={e => onImport(e.target.files[0])}
        />
        <ImportSvg />
        {t('programEdition.import')} xlsx
      </LegendIcon>
      <DashedSeparator />
      <LegendIcon onClick={() => onExport()}>
        <ExportSvg />
        {t('programEdition.export')} xlsx
      </LegendIcon>
    </LegendWrapper>
  )
}
